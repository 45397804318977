import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Sun, Moon } from 'lucide-react';
import logo from '../assets/logo.png';
import { logoutUser } from '../redux/Login';
import userlogo from '../assets/kalyan.jpeg';
import { ThemeContext } from '../ThemeContext';
import { setAIEnabled} from '../redux/Setting';


function Navbar({ toggleSidebar }) {
    const dispatch = useDispatch();
    const [isMenuVisible, setMenuVisible] = useState(false);
    const { isDarkMode, toggleTheme, theme } = useContext(ThemeContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const isAIEnabled = useSelector((state) => state.setting.isAIEnabled);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleProfile = () => {
        setMenuVisible(!isMenuVisible);
    };

    const handleOutsideClick = (event) => {
        if (isMenuVisible && !event.target.closest('.flex-none')) {
            setMenuVisible(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isMenuVisible]);

    const handleAIEnabledToggle = () => {
        dispatch(setAIEnabled(!isAIEnabled));
    };

    return (
        <nav className={`p-4 flex justify-between items-center ${theme.primaryColor} ${theme.secondaryColor} ${theme.borderWidth} ${theme.borderColor}`}>
            <div className="flex items-center">
                <button onClick={toggleSidebar} className="mr-4 md:hidden">
                    <Menu size={24} />
                </button>
                <img src={logo} alt="" className="h-10 mr-6 rounded-full" />
                <h1 className="text-xl md:text-2xl font-bold">Bionic Report</h1>
            </div>
            <div className="flex items-center">
                {!isMobile && (
                    <button onClick={toggleTheme} className="mr-4">
                        {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
                    </button>
                )}
                <div className="flex-none gap-2 relative">
                    <img
                        style={{ borderRadius: "50%" }}
                        className="w-12 h-12"
                        alt=""
                        src={userlogo}
                        onClick={handleProfile}
                    />
                    {isMenuVisible && (
                        <div
                            className={`absolute right-0 top-full mt-2 w-32 origin-top-right rounded-md ${theme.primaryColor} ${theme.borderWidth} ${theme.borderColor} shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                        >
                            <div className="py-1" role="none">
                                <a
                                    className={`block px-4 py-2 border-b text-sm ${theme.secondaryColor}`}
                                    role="menuitem"
                                    id="menu-item-0"
                                >
                                    Profile
                                </a>
                                {isMobile && (
                                    <button
                                        onClick={toggleTheme}
                                        className={`block w-full border-b px-4 py-2 text-left text-sm ${theme.secondaryColor}`}
                                        role="menuitem"
                                    >
                                        {isDarkMode ? 'Light Mode' : 'Dark Mode'}
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className={`block w-full px-4 py-2 text-left  border-b text-sm ${theme.secondaryColor}`}
                                    role="menuitem"
                                    id="menu-item-3"
                                    onClick={() => {
                                        dispatch(logoutUser());
                                    }}
                                >
                                    Log Out
                                </button>
                                <button
                                    onClick={handleAIEnabledToggle}
                                    className={`block w-full border-b px-4 py-2 text-left text-sm ${theme.secondaryColor}`}
                                    role="menuitem"
                                >
                                    {isAIEnabled ? 'Disable AI' : 'Enable AI'}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
}

export default Navbar;