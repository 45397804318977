import { createSlice } from '@reduxjs/toolkit';

const augnitoSlice = createSlice({
  name: 'augnito',
  initialState: {
    isListening:false
    },
  reducers: {
    augnitoStartListening: (state) => {
      state.isListening = true;
    },
    augnitoStopListening: (state) => {
      state.isListening = false;
    }
  },
});

export const { augnitoStartListening, augnitoStopListening } = augnitoSlice.actions;

export default augnitoSlice.reducer;