import { createSlice } from '@reduxjs/toolkit';

const chatMessageSlice = createSlice({
  name: 'chatMessages',
  initialState: {
    messages: [],
    currentText: '',
    initialDescription: '',
  },
  reducers: {
    addMessage: (state, action) => {
      state.messages = [ action.payload];
    },
    resetMessage: (state) => {
      state.messages = []
    },
    setInitialDescription: (state, action) => {
      state.initialDescription = action.payload;
    },
    setCurrentText: (state, action) => {
      state.currentText = action.payload;
    }
  },
});

export const { addMessage, resetMessage, setInitialDescription, setCurrentText } = chatMessageSlice.actions;

export default chatMessageSlice.reducer;