import { OpenAI } from 'openai';
import { setInitialDescription } from '../redux/ChatMessages';
import { useDispatch, useSelector } from 'react-redux';

const openai_api_key = process.env.REACT_APP_OPENAI_API_KEY;
const openai_api_base = process.env.REACT_APP_OPENAI_API_BASE;

const client = new OpenAI({
    apiKey: openai_api_key,
    dangerouslyAllowBrowser: true,
    baseURL: openai_api_base,
});

const GemmaComponent = (props) => {
        const {sendMessageStream} = props
        const dispatch = useDispatch();
        const { currentText} = useSelector(state => ({
            currentText: state.chatMessages.currentText
        }));
  
        // const report = `
        // The liver measures 13.8 cm with mild fatty infiltration, and the spleen is mildly enlarged at 13.6 cm with a likely hemangioma measuring 2.0 x 1.8 cm at the inferior pole. Both kidneys are normal in size and echotexture, with bilateral renal papillary tip calcifications noted. The urinary bladder is distended with prevoid volume of 358 cc and postvoid volume of 16 cc, and the wall thickness is normal. No intra hepatic duct dilatation, focal liver lesions, gallbladder calculi, or free fluid in the abdomen and pelvis were observed, and the pancreas is normal in size and echotexture.
        // `;

        const getModels = async () => {
            const models = await client.models.list();
            return models.data[0].id;
        };

        const stream = true;
        
        const replacements = {
            "LIVER:": "Liver:",
            "CBD:": "CBD: ",
            "SPLEEN:": "Spleen: ",
            "UTERUS:": "Uterus:",
            "PORTAL VEIN:": "Portal Vein:",
            "HEPATIC VESSELS:": "Hepatic Vessels:",
            "GALL BLADDER:": "Gall Bladder:",
            "KIDNEY:": "Kidney:",   
            "KIDNEYS:": "Kidneys:",
            "OVARY:": "Ovary:",
            "URINARY BLADDER:": "Urinary Bladder:",
            "PARA AORTIC AREA:": "Para Aortic Area:",
            "PANCREAS:": "Pancreas:",
            "APPENDIX:": "Appendix:",
            "OVARIES:": "\nOvaries:",    
            "PROSTATE:": "Prostate:",
            "RIF" : "RIF",
            "No free fluid in the abdomen and pelvis.": "\nNo free fluid in the abdomen and pelvis.",
        };

        const replaceFn = (text, buffer) => {
            let result = buffer + text;
            let toPrint = "";
            for (const [old, newText] of Object.entries(replacements)) {
                result = result.replace(old, newText);
            }
            
            result = result.replace(/(?<!\d)(\.\s|\.$)/g, '.\n');
            result = result.replace(/\n{2,}/g, '\n\n');
            result = result.split('\n').map(line => line.trimLeft()).join('\n');

            const lastNewline = result.lastIndexOf('\n');
            
            if (lastNewline !== -1) {
                toPrint = result.slice(0, lastNewline + 1);
                const newBuffer = result.slice(lastNewline + 1);
                return [newBuffer, toPrint];
            } else {
                return [result, ""];
            }
        };

        const processStream = async (completion) => {
            const startTime = Date.now();
            let buffer = "";
            let toPrint = "";

            for await (const c of completion) {
                const chunk = c.choices[0].text;
                [buffer, toPrint] = replaceFn(chunk, buffer);                
                sendMessageStream(toPrint);
            }
            const endTime = Date.now();
            const elapsedTime = (endTime - startTime) / 1000;
        };


        const runGemmaCompletion = async () => {
            
        try {
            const reportText = currentText;
            const prompts = [`<start_of_turn> user \n
                Bring both the kidneys under same organ KIDNEYS, do not separate them. Remove normal lines in case of abnormal lines.
                Consider yourself as an awesome radiologist on abdomen USG and with initial description generate a full fledged radiological report (Observation and Impression).
                Make sure to call all the possible organ headers if present. 
                Use "/n" for line breaks to improve readability. Separate each organ system and the Impression section clearly.
                Return only observation and impression nothing else.\n
                Report Name:- USG Abdomen and Pelvis \n  
                Initial Description:- ${reportText} \n 
                <end_of_turn>
                <start_of_turn>model\n
                `];
                const createCompletion = async (model) => {
                    return client.completions.create({
                model: model,
                prompt: prompts,
                stream: stream,
                temperature: 0.25,
                max_tokens: 1024,
                stop: ["[/assistant]"]
            });
        };


            dispatch(setInitialDescription(reportText));
            const model = await getModels();
            const completion = await createCompletion(model, prompts.DEFAULT_SYSTEM_PROMPT);
            await processStream(completion);
        } catch (error) {
            console.error("Error running Gemma completion:", error);
        }
        };

        return (
            
            <button id="stream-button" className=" border border-blue-700 font-semibold py-2 px-3 rounded inline-flex items-center"
            onClick={runGemmaCompletion}
           >
            <svg width="28" height="28" viewBox="0 0 11 11" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path fill= "currentColor" d="M8.86748 4.80655V3.97992H8.04084V3.15328C8.04019 2.93424 7.95289 2.72436 7.798 2.56948C7.64312 2.41459 7.43324 2.32729 7.2142 2.32664H6.38757V1.5H5.56093V2.32664H4.73429V1.5H3.90765V2.32664H3.08101C2.86197 2.32729 2.6521 2.41459 2.49721 2.56948C2.34233 2.72436 2.25503 2.93424 2.25437 3.15328V3.97992H1.42773V4.80655H2.25437V5.63319H1.42773V6.45983H2.25437V7.28647C2.25503 7.50551 2.34233 7.71539 2.49721 7.87027C2.6521 8.02515 2.86197 8.11245 3.08101 8.11311H3.90765V8.93975H4.73429V8.11311H5.56093V8.93975H6.38757V8.11311H7.2142C7.43324 8.11245 7.64312 8.02515 7.798 7.87027C7.95289 7.71539 8.04019 7.50551 8.04084 7.28647V6.45983H8.86748V5.63319H8.04084V4.80655H8.86748ZM7.2142 7.28647H3.08101V3.15328H7.2142V7.28647Z" />
              <path fill= "currentColor" d="M4.88473 3.56677H4.32882L3.49805 6.87333H3.92253L4.11431 6.09835H5.07155L5.25837 6.87333H5.69732L4.88473 3.56677ZM4.1701 5.76728L4.59086 3.95943H4.60988L5.01617 5.76728H4.1701ZM6.07633 3.56677H6.48965V6.87333H6.07633V3.56677Z" />
            </svg>
            <span className="text-sm hidden md:inline">Generate&nbsp;Report</span>
          </button> 
            
        )

        }

export default GemmaComponent;

