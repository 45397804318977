// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducerLogin from './Login';
import userReducerReport from './ReportContents';
import userChatMessages from './ChatMessages';
import userSelectedModStudy from './SelectedModStudy';
import pathologyFormData from './PathologyFormData';
import augnito from './Augnito'
import settingReducer from './Setting';


export const store = configureStore({
  reducer: {
    user: userReducerLogin,
    reportContents : userReducerReport,
    chatMessages : userChatMessages,
    selectedModStudy : userSelectedModStudy,
    pathologyFormData: pathologyFormData,
    augnito : augnito,
    setting : settingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['pathologies/flipIsReportable'],
        ignoredPaths: ['user.user'],
      },
    })
});