import React, { useEffect, useState, useContext } from 'react';
import Questions from './Question';
import Navbar from './Navbar';
import Editor from './Editor';
import { loginSuccess } from '../redux/Login';
import Login from './Login';
import { useSelector, useDispatch } from 'react-redux';
import SidebarItem from './Side';
import { ThemeContext } from '../ThemeContext';

function getQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const accessToken = searchParams.get('accessToken');
  const idToken = searchParams.get('idToken');

  return { accessToken, idToken };
}

function MainChatContainer() {
  const { accessToken, idToken } = getQueryParams();
  const user = useSelector((state) => state.user);
  const isChatQuestionActive = useSelector((state) => state.reportContents.isChatQuestionActive);
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const validateUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_USER_IDENTITY_API}`, {
          method: 'GET',
          headers: {
            idToken: idToken,
            accessToken: accessToken,
          },
        });
        if (!response.ok) {
          console.error('User validation failed:', response.status);
          return;
        }
        const userData = await response.json();
        dispatch(loginSuccess(userData.user));
      } catch (error) {
        console.error('Error validating user:', error);
      }
    };

    if (accessToken && idToken) {
      validateUser();
    }
  }, [accessToken, idToken, dispatch]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  return (
    <div className={`App ${theme.backgroundColor} ${theme.secondaryColor} ${theme.borderWidth} ${theme.borderColor}`}>
      {!user?.user ? (
        <Login />
      ) : (
        <div className="h-screen overflow-hidden flex flex-col max-w-6xl mx-auto">
          <div className="w-full">
            <Navbar toggleSidebar={toggleSidebar} />
          </div>
          <div className="flex-grow flex">
            <div className={`md:w-[35%] flex-shrink-0 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'w-full md:w-[35%]' : 'w-0 md:w-[35%]'}`}>
              <div className={`h-full mt-5 overflow-y-auto ${isSidebarOpen ? 'block' : 'hidden md:block'}`}>
                <SidebarItem />
              </div>
            </div>
            <div className={`flex-grow flex flex-col transition-all duration-300 ease-in-out ${isSidebarOpen ? 'hidden md:flex' : 'flex'}`}>
              <Questions />
              <div className="flex-grow flex justify-center items-center w-[98%] ">
                <div
                  className={`w-full h-full flex-grow flex flex-col ${
                     isChatQuestionActive ? 'blur-sm' : ''
                  }`}
                >
                  <Editor />
                </div>
              </div>
              <div className="h-[4vh]">
                <p className="pr-5 text-right text-xs md:text-base">
                  © Copyright 2016 - 2024 by 5C Network. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainChatContainer;