import React, { useState, useContext } from 'react';
import { ChevronRight, Plus, Save, RefreshCw, X, ChevronDown } from 'lucide-react';
import { ThemeContext } from '../ThemeContext';

const SidebarItem = ({ label, expanded, onToggle, children }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div>
      <div 
        className={`flex items-center px-4 py-2 cursor-pointer ${theme.primaryColor}  hover:${theme.isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}
        onClick={onToggle}
      >
        <ChevronRight 
          size={16} 
          className={`mr-2 transition-transform ${expanded ? 'rotate-90' : ''}`}
        />
        <span className={`${theme.secondaryColor} text-sm`}>{label}</span>
      </div>
      {expanded && children}
    </div>
  );
};

const VariableDropdown = ({ variable, onUpdate, onRemove, onSave, isExisting }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`mb-2 ${theme.primaryColor} ${theme.borderWidth} ${theme.borderColor} rounded`}>
      <div 
        className="flex justify-between items-center p-2 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`text-sm ${theme.secondaryColor}`}>{variable.name || 'New Variable'}</span>
        <ChevronDown size={16} className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </div>
      {isOpen && (
        <div className="p-2 space-y-2">
          <input
            className={`w-full p-1 ${theme.primaryColor} ${theme.borderWidth} ${theme.borderColor} ${theme.secondaryColor} rounded text-xs`}
            placeholder="Variable Name"
            value={variable.name}
            onChange={(e) => onUpdate({ ...variable, name: e.target.value })}
          />
          <select
            className={`w-full p-1 ${theme.primaryColor} ${theme.borderWidth} ${theme.borderColor} ${theme.secondaryColor} rounded text-xs`}
            value={variable.type}
            onChange={(e) => onUpdate({ ...variable, type: e.target.value })}
          >
            <option value="Str">String</option>
            <option value="Int">Integer</option>
            <option value="Single Choice">Single Choice</option>
            <option value="Multi Choice">Multi Choice</option>
          </select>
          <input
            className={`w-full p-1 ${theme.primaryColor} ${theme.borderWidth} ${theme.borderColor} ${theme.secondaryColor} rounded text-xs`}
            placeholder="Value(s) (comma-separated for choices)"
            value={Array.isArray(variable.value) ? variable.value.join(', ') : variable.value}
            onChange={(e) => onUpdate({ ...variable, value: e.target.value })}
          />
          <div className="flex justify-center space-x-2">
            <button 
              className="w-[30%] bg-blue-600 text-white p-1 rounded text-xs flex items-center justify-center"
              onClick={onSave}
            >
              <Save size={12} className="mr-1" /> {isExisting ? 'Update' : 'Save'}
            </button>
            <button 
              className="w-[30%] bg-red-600 text-white p-1 rounded text-xs flex items-center justify-center"
              onClick={onRemove}
            >
              <X size={12} className="mr-1" /> Remove
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const VSCodeSidebar = () => {
  const { theme } = useContext(ThemeContext);
  const [expandedItems, setExpandedItems] = useState({
    templates: false,
    pathology: false,
  });
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedPathology, setSelectedPathology] = useState('');
  const [editingPathology, setEditingPathology] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isFetchingVariables, setIsFetchingVariables] = useState(false);

  const templates = ['Template 1', 'Template 2', 'Template 3'];
  const [pathologies, setPathologies] = useState([
    { name: 'Pathology 1', description: 'Description 1', variables: [
      { name: 'Var1', type: 'Str', value: 'Value1' },
      { name: 'Var2', type: 'Int', value: '10' }
    ]},
    { name: 'Pathology 2', description: 'Description 2', variables: [
      { name: 'Var3', type: 'Single Choice', value: ['Option1', 'Option2'] },
      { name: 'Var4', type: 'Multi Choice', value: ['OptionA', 'OptionB', 'OptionC'] }
    ]},
  ]);

  const toggleItem = (item) => {
    setExpandedItems(prev => ({ ...prev, [item]: !prev[item] }));
  };

  const handleAddNewPathology = () => {
    setSelectedPathology('');
    setEditingPathology({ name: '', description: '', variables: [] });
  };

  const handleSavePathology = () => {
    if (!editingPathology.name) return; // Prevent saving without a name

    if (selectedPathology) {
      setPathologies(prev => prev.map(p => 
        p.name === selectedPathology ? editingPathology : p
      ));
    } else {
      setPathologies(prev => [...prev, editingPathology]);
    }
    setSelectedPathology(editingPathology.name);
    setEditingPathology(null);
  };

  const handleSaveVariable = (index) => {
    console.log(`Saving variable at index ${index}`);
  };

  const generateDescription = async () => {
    setIsGenerating(true);
    setTimeout(() => {
      const generatedText = `This is a generated description for ${editingPathology.name}. It includes information about the pathology and its variables.`;
      setEditingPathology(prev => ({ ...prev, description: generatedText }));
      setIsGenerating(false);
    }, 2000);
  };

  const autoFetchVariables = async () => {
    setIsFetchingVariables(true);
    setTimeout(() => {
      const fetchedVariables = [
        { name: 'AutoVar1', type: 'Str', value: 'AutoValue1' },
        { name: 'AutoVar2', type: 'Int', value: '20' }
      ];
      setEditingPathology(prev => ({ 
        ...prev, 
        variables: [...prev.variables, ...fetchedVariables]
      }));
      setIsFetchingVariables(false);
    }, 2000);
  };

  const addVariable = () => {
    setEditingPathology(prev => ({
      ...prev,
      variables: [...prev.variables, { name: '', type: 'Str', value: '' }]
    }));
  };

  const updateVariable = (index, updatedVariable) => {
    setEditingPathology(prev => ({
      ...prev,
      variables: prev.variables.map((v, i) => 
        i === index ? updatedVariable : v
      )
    }));
  };

  const removeVariable = (index) => {
    setEditingPathology(prev => ({
      ...prev,
      variables: prev.variables.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className={`w-full h-[84vh] ${theme.borderWidth} ${theme.borderColor} overflow-auto ${theme.primaryColor} ${theme.secondaryColor}`}>
      <div className={`flex items-center justify-between p-2`}>
        <span className={`text-2xl font-bold text-center w-full block ${theme.secondaryColor}`}>USG Bionic</span>
      </div>
      <div className={`${theme.borderWidth} ${theme.borderColor} my-2`}></div>
      <SidebarItem 
        label="TEMPLATES" 
        expanded={expandedItems.templates}
        onToggle={() => toggleItem('templates')}
      >
        <div className="px-6 py-2 space-y-2">
          <select
            className={`w-full p-1 ${theme.primaryColor} ${theme.borderWidth} ${theme.borderColor} ${theme.secondaryColor} rounded text-sm`}
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
          >
            <option value="">Choose Template</option>
            {templates.map((template, index) => (
              <option key={index} value={template}>{template}</option>
            ))}
          </select>
          <div className="flex justify-center">
            <button className="w-[30%] bg-blue-600 text-white p-1 rounded text-sm flex items-center justify-center">
              <Plus size={14} className="mr-1" /> Add
            </button>
          </div>
        </div>
      </SidebarItem>
      <SidebarItem 
        label="PATHOLOGY" 
        expanded={expandedItems.pathology}
        onToggle={() => toggleItem('pathology')}
      >
        <div className="px-6 py-2 space-y-2">
          <select
            className={`w-full p-1 ${theme.primaryColor} ${theme.borderWidth} ${theme.borderColor} ${theme.secondaryColor} rounded text-sm`}
            value={selectedPathology}
            onChange={(e) => {
              const selected = pathologies.find(p => p.name === e.target.value);
              setSelectedPathology(e.target.value);
              setEditingPathology(selected ? { ...selected } : null);
            }}
          >
            <option value="">Choose Pathology</option>
            {pathologies.map((pathology, index) => (
              <option key={index} value={pathology.name}>{pathology.name}</option>
            ))}
          </select>
          {!selectedPathology && (
            <div className="flex justify-center">
              <button 
                className="w-[30%] bg-blue-600 text-white p-1 rounded text-sm flex items-center justify-center"
                onClick={handleAddNewPathology}
              >
                <Plus size={14} className="mr-1" /> Add
              </button>
            </div>
          )}
          {editingPathology && (
            <div className="space-y-2 mt-4">
              <input
                className={`w-full p-1 ${theme.primaryColor} ${theme.borderWidth} ${theme.borderColor} ${theme.secondaryColor} rounded text-sm`}
                placeholder="Pathology Name"
                value={editingPathology.name}
                onChange={(e) => setEditingPathology(prev => ({ ...prev, name: e.target.value }))}
              />
              <div className="flex justify-center">
                <button 
                  className="w-[50%] bg-green-600 text-white p-1 rounded text-xs flex items-center justify-center"
                  onClick={generateDescription}
                  disabled={isGenerating}
                >
                  <RefreshCw size={12} className={`mr-1 ${isGenerating ? 'animate-spin' : ''}`} /> 
                  {isGenerating ? 'Generating...' : 'Generate Description'}
                </button>
              </div>
              <textarea
                className={`w-full h-40 p-1 ${theme.primaryColor} ${theme.borderWidth} ${theme.borderColor} ${theme.secondaryColor} rounded text-sm`}
                placeholder="Pathology Description"
                value={editingPathology.description}
                onChange={(e) => setEditingPathology(prev => ({ ...prev, description: e.target.value }))}
              />
              <div className="flex justify-between items-center">
                <p className={`font-bold text-sm ${theme.secondaryColor}`}>Variables:</p>
                <button 
                  className="w-[30%] bg-blue-600 text-white p-1 rounded text-xs flex items-center justify-center"
                  onClick={autoFetchVariables}
                  disabled={isFetchingVariables}
                >
                  <RefreshCw size={12} className={`mr-1 ${isFetchingVariables ? 'animate-spin' : ''}`} /> 
                  {isFetchingVariables ? 'Fetching...' : 'Auto Fetch'}
                </button>
              </div>
              {editingPathology.variables.map((variable, index) => (
                <VariableDropdown
                  key={index}
                  variable={variable}
                  onUpdate={(updatedVariable) => updateVariable(index, updatedVariable)}
                  onRemove={() => removeVariable(index)}
                  onSave={() => handleSaveVariable(index)}
                  isExisting={selectedPathology !== ''}
                />
              ))}
              <div className="flex justify-center">
                <button 
                  className="w-[30%] bg-blue-600 text-white p-1 rounded text-xs flex items-center justify-center"
                  onClick={addVariable}
                >
                  <Plus size={12} className="mr-1" /> Add Variable
                </button>
              </div>
              <div className="flex justify-center">
                <button 
                  className="w-[30%] bg-green-600 text-white p-1 rounded text-xs flex items-center justify-center"
                  onClick={handleSavePathology}
                >
                  <Save size={12} className="mr-1" /> {selectedPathology ? 'Update' : 'Save'}
                </button>
              </div>
            </div>
          )}
        </div>
      </SidebarItem>
    </div>
  );
};

export default VSCodeSidebar;