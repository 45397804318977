import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [theme, setTheme] = useState({
    primaryColor: 'bg-darkPrimary',
    secondaryColor: 'text-darkSecondary',
    backgroundColor: 'bg-darkBackgroundColor',
    borderColor: 'border-darkBorderColor',
    borderWidth: 'border',
  });

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'light') {
      setIsDarkMode(false);
      document.documentElement.classList.remove('dark');
    } else {
      document.documentElement.classList.add('dark');
    }
  }, []);

  useEffect(() => {
    setTheme({
      primaryColor: isDarkMode ? 'bg-darkPrimary' : 'bg-lightPrimary',
      secondaryColor: isDarkMode ? 'text-darkSecondary' : 'text-lightSecondary',
      backgroundColor: isDarkMode ? 'bg-darkBackgroundColor' : 'bg-lightBackgroundColor',
      borderColor: isDarkMode ? 'border-darkBorderColor' : 'border-lightBorderColor',
      borderWidth: 'border',
    });
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    } else {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    }
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};