import { createSlice } from '@reduxjs/toolkit';

const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    isAIEnabled: false,
  },
  reducers: {
    toggleAI: (state) => {
      state.isAIEnabled = !state.isAIEnabled;
    },
    setAIEnabled: (state, action) => {
      state.isAIEnabled = action.payload;
    },
  },
});

export const { toggleAI, setAIEnabled } = settingSlice.actions;

export default settingSlice.reducer;
