// loginSlice.js
import { createSlice } from '@reduxjs/toolkit';
import firebase from '../utils/Firebase';

const loginSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    loading: true,
    error: null,
    isEnabled:null
  },
  reducers: {
    loginStart: state => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    loginFailure: state => {
      state.loading = false;
      state.error = 'Wrong username or password. Try again.';
    },
    logoutSuccess: state => {
        state.user = null;
        state.loading = false;
      },
      setEnabled: (state, action) => {
        state.isEnabled = action.payload;
      }
  },
});

export const { loginStart, loginSuccess, loginFailure, logoutSuccess ,setEnabled} = loginSlice.actions;

export const loginUser = (email, password) => async dispatch => {
  dispatch(loginStart());
  try {
    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
    dispatch(loginSuccess(userCredential.user));
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

export const logoutUser = () => async dispatch => {
    try {
      await firebase.auth().signOut();
      dispatch(logoutSuccess());
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

export default loginSlice.reducer;
