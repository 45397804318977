import {  useCallback, useMemo, useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { augnitoStartListening,augnitoStopListening} from '../redux/Augnito'
import { Augnito, AugnitoAPIServer } from 'augnitosdk'
import styled from 'styled-components'
import { MicFill, MicMuteFill } from '@styled-icons/bootstrap'



const augnitoConfigSetup = () => {
  const augnitoConfig = {
    server: AugnitoAPIServer.INDIA,
    accountCode: process.env.REACT_APP_AUGNITO_ACCOUNT_CODE,
    accessKey: process.env.REACT_APP_AUGNITO_ACCESS_KEY,
    userTag: 123423,
    sourceApp: '5C-Network',
    lmId: process.env.REACT_APP_AUGNITO_LMID,
  }
  const augnito = new Augnito(augnitoConfig)
  return augnito
}



const AugnitoSDK = (props) => {
  const { sendMessageStream } = props
  const dispatch=useDispatch();
  const augnitoStateConnection = useSelector((state) => state.augnito.isListening);
  const augnito = useMemo(() => augnitoConfigSetup(), [])

  augnito.onIdleMic = () => {
    augnito.toggleListening()
  }

  augnito.onFinalResult = useCallback((finalText) => {
    const handled = true 
    sendMessageStream(finalText?.receivedText)
    return handled
  }, [])

  const handleConnectionOff = () => {
    augnito.toggleListening()
    dispatch(augnitoStopListening())
  }

  const handleConnectionOn = () => {
    augnito.toggleListening()
    dispatch(augnitoStartListening())
  }


useEffect(()=>{
  if(augnitoStateConnection){
    augnito.toggleListening()
  }
},[augnitoStateConnection])
  

  return (
    <AugnitoContainer>
      <ToolbarAugnitoContainer>
        {augnitoStateConnection ? (
          <IconButton onClick={handleConnectionOff}>
            <MicFill size={24} />
          </IconButton>
        ) : (
          <IconButton onClick={handleConnectionOn}>
            <MicMuteFill size={24} />
          </IconButton>
        )}
      </ToolbarAugnitoContainer>
    </AugnitoContainer>
  )
}

const IconButton = styled.button`
  cursor: pointer;
  border: 1px solid currentColor;
  padding: 3px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  svg {
    fill: currentColor;
  }
`;

export default AugnitoSDK

const AugnitoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const MicIcon = styled(MicFill)`
  cursor: pointer;
  border: 1px solid white;
  padding: 3px;
  border-radius: 50%;
`

const MicMuteIcon = styled(MicMuteFill)`
  cursor: pointer;
  border: 1px solid white;
  padding: 3px;
  border-radius: 50%;
`

const AugnitoInput = styled.input`
  border-radius: 0.3rem;
  border: 1px solid grey;
`

const ToolbarAugnitoContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
`