// Login.js
import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, loginSuccess, logoutSuccess} from '../redux/Login';
import firebase from './Firebase';
import { ThemeContext } from '../ThemeContext';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const { loading, error } = useSelector(state => state.user);
    const { theme } = useContext(ThemeContext);

    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                dispatch(loginSuccess(user));
            } else {
                dispatch(logoutSuccess());
            }
        });

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            unsubscribe();
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(loginUser(email, password));
    };

    if (loading) {
        return (
            <div className={`flex items-center justify-center h-screen ${theme.backgroundColor}`}>
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className={`min-h-screen flex items-center justify-center ${theme.backgroundColor} px-4`}>
            <div className={`w-full ${isMobile ? 'max-w-sm' : 'sm:max-w-md'} ${theme.primaryColor} rounded-lg shadow`}>
                <div className="p-6 space-y-4 md:space-y-6">
                    <h1 className={`text-xl font-bold leading-tight tracking-tight ${theme.secondaryColor} md:text-2xl text-center`}>
                        Sign in
                    </h1>
                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit} action="#">
                        <div>
                            <label htmlFor="email" className={`block mb-2 text-sm font-medium ${theme.secondaryColor}`}>Your email</label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" id="email" className={`${theme.backgroundColor} ${theme.borderColor} ${theme.secondaryColor} sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5`} placeholder="name@5cnetwork.com" required />
                        </div>
                        <div>
                            <label htmlFor="password" className={`block mb-2 text-sm font-medium ${theme.secondaryColor}`}>Password</label>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="password" id="password" placeholder="••••••••" className={`${theme.backgroundColor} ${theme.borderColor} ${theme.secondaryColor} sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5`} required />
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input id="remember" aria-describedby="remember" type="checkbox" className={`w-4 h-4 ${theme.borderColor} rounded ${theme.backgroundColor} focus:ring-3 focus:ring-blue-300`} />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="remember" className={theme.secondaryColor}>Remember me</label>
                                </div>
                            </div>
                        </div>
                        <div id="error-message" className="mt-2 text-center text-red-500">{error}</div>
                        <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Sign in</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
