import React, {useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetMessage } from '../redux/ChatMessages';
import { makeIsToBeQuestionedFalse, replaceAddtionalObservation, flipChatQuestion, replaceAdditionalImpression } from '../redux/ReportContents';
import { updatePathologyFormData } from '../redux/PathologyFormData';
import { getAdditionalText, renderInput } from './Helper';
import { ThemeContext } from '../ThemeContext';

function Questions() {
  const dispatch = useDispatch();
  const [measurementSize, setMeasurementSize] = useState({ CC: '', AP: '', TR: '' });
  const { theme } = useContext(ThemeContext);

  const { reportContents, messages, pathologyFormData, isChatQuestionActive } = useSelector(state => ({
    reportContents: state.reportContents.reportContents,
    messages: state.chatMessages.messages,
    pathologyFormData: state.pathologyFormData.pathologyFormData,
    isChatQuestionActive: state.reportContents.isChatQuestionActive,
  }));

  const handlePathologyFormDataChange = (key, value, pathology) => {
    dispatch(updatePathologyFormData({ key, value, pathology }));
  };

  const updateMeasurementSize = (event, key, dimension, pathology, ) => {
    const value = event.target.value;
    setMeasurementSize(prevMeasurementSize => {
      const newMeasurementSize = { ...prevMeasurementSize, [dimension]: value };
      handlePathologyFormDataChange(key, `${newMeasurementSize.CC} x ${newMeasurementSize.AP} x ${newMeasurementSize.TR}`, pathology)
      return newMeasurementSize;
    });
  };

  function getVaildVariablesForFollowUp(question, selectedOptions) {
    const variables = [];
    for (const input of question) {
      if (input?.key && input.type !== 'heading' && input.type !== 'link') {
        variables.push(input.key);
        if (input.followup === 'true') {
          const selectedOption = selectedOptions?.[input.key];
          if (selectedOption) {
            const followupInput = input.to_followup[selectedOption];
            if (followupInput) {
              for (const key in followupInput) {
                  variables.push(...getVaildVariablesForFollowUp([followupInput[key]], selectedOptions));
                
              }
            }
          }
        }
      }
    }
    return variables;
  }
  

  const isFormValidForSubmmit = (pathology) => {
    const reportContent = reportContents.find(content => content.pathology === pathology);
    if (!reportContent || !pathologyFormData) {
      return false;
    }
    const vaildVariables = getVaildVariablesForFollowUp(reportContent.question, pathologyFormData[pathology]);

    const formDataKeys = Object.keys(pathologyFormData?.[pathology] || {});
    let formVaildKeys = [];
    formDataKeys.forEach(key => {
      if (pathologyFormData[pathology][key] !== '') {
        formVaildKeys.push(key);
      }
    })

    if (vaildVariables.every(variable => formVaildKeys.includes(variable))) {
      return true;
    }
    return false;
  };
  

  const handleSubmit = (event, pathology) => {
    event.preventDefault();
    dispatch(flipChatQuestion(false));
    dispatch(resetMessage());
    dispatch(makeIsToBeQuestionedFalse(pathology));
    reportContents.forEach(content => {
      if (content.pathology === pathology) {

        let additionalText = getAdditionalText(content.question, pathologyFormData[content.pathology]);
        let addtionalObservation = '';
        if (additionalText.length > 0 && additionalText[0].charAt(0).toUpperCase() === additionalText[0].charAt(0)) {
          addtionalObservation = content.observation + '\n' + additionalText;
        }
        else{
          addtionalObservation = content.observation.slice(0, -1) + ' ' + additionalText;
        }

        for (const [key, value] of Object.entries(pathologyFormData[content.pathology])) {
          if (addtionalObservation.includes(`_${key}`)) {
            addtionalObservation = addtionalObservation.replace(new RegExp(`_${key}`, 'g'), value);
          }
        }

        dispatch(replaceAddtionalObservation({ 'pathology': pathology, 'observation': addtionalObservation}))

        let additionalImpression = content.impression;

        for (const [key, value] of Object.entries(pathologyFormData[content.pathology])) {
          if (additionalImpression.includes(`_${key}`)) {
            additionalImpression = additionalImpression.replace(new RegExp(`_${key}`, 'g'), value);
          }
        }
        dispatch(replaceAdditionalImpression({ 'pathology': pathology, 'impression': additionalImpression }))
      }
    });

  }
  const renderForm = (message) => {
    return (
      <form id={message.pathology} onSubmit={(event) => { handleSubmit(event, message.pathology); }}>
        {message.question.map((input, index) =>  renderInput(input, message.variable, message.pathology, handlePathologyFormDataChange, measurementSize, updateMeasurementSize, pathologyFormData, theme, index))}
        <button className={`inline-flex mt-4 rounded-lg focus:outline-none order-2 ${isFormValidForSubmmit(message.pathology) ? 'transition-transform transform scale-150' : ''} ${theme.primaryColor}`} disabled={!isFormValidForSubmmit(message.pathology)} style={{ float: 'right' }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -2 20 20" fill="currentColor" className="ml-2 h-4 w-4 rotate-90 transform">
            <path fill={theme.secondaryColor} d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
          </svg>
        </button>
      </form>
    );
  }


  return (
    isChatQuestionActive ? (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 w-full">
          {(() => {
            const message = messages[0];
            return (
              <div className="flex w-full">
                  {message?.text && (
                    <div className={`chat-bubble w-full sm:w-4/5 md:w-3/5 lg:w-2/5 mx-auto p-4 ${theme.primaryColor} ${theme.secondaryColor}`}>
                          {renderForm(message.text)}
                        </div>
                      )}
                  </div>
            );
          })()}
        </div>
    ) : null
  );
  
}

export default Questions;