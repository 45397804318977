import Select from 'react-select';
import { organ_list } from '../constant';

export function findVariableProperty(reportContents, pathology, variableName, property) {

  if(pathology === 'normal'){
    return 'input box'
  }

  const reportContent = reportContents.find(content => content.pathology === pathology);
  if (reportContent && reportContent?.question.length > 0) {
    const question = reportContent.question.find(input => input.key === variableName.replace('_', ''));
    if (question && question.hasOwnProperty(property)) {
      return question[property];
    }
  }
  return null;
}

export function streamPathologyContent(data) {
  let observation = '';

  

  if (data['pathology'] !== 'normal') {
      let formattedObservation = data['observation'];
      formattedObservation = formattedObservation?.replace(/\b_\w+/g, `<span id="obs-${data['pathology']}" style="background-color: #ffff00; color: black;" >$&</span>`);
      formattedObservation = formattedObservation?.replace(/^[a-z]/, (match) => match.toUpperCase());
      observation += `<p id="obs-${data['pathology']}">${formattedObservation}</p>`;
      
    }

  return observation ;
}


export function streamFullReportContent(data, selectedModStudyValue) {
  let observation = '<h3 id="observation-header"><b>Observation:-</b></h3>';
  let impression = '\n<h3 id="impression-header"><b>Impression:-</b></h3>';

  organ_list[selectedModStudyValue].map((organ) => {
    let temp_observation = '';
    let temp_impression = '';

    data.filter(i => i['organ'] === organ && i['pathology'] !== 'normal').map((obs) => {
      let formattedObservation = obs['observation']

      formattedObservation = formattedObservation.replace(/\b_\w+/g, `<span id="obs-${obs['pathology']}" style="background-color: #ffff00; color: black;" >$&</span>`);
      if (obs['observation'] !== 'NA') temp_observation += `<p id="obs-${obs['pathology']}">` + formattedObservation+ '</p>';
      if (obs['impression'] !== 'NA') temp_impression += `<p id="imp-${obs['pathology']}">` + obs['impression'] + '</p>';
    });


    data.filter(i => i['organ'] === organ && i['pathology'] === 'normal').map((obs) => {
      let formattedObservation = obs['observation'];
      formattedObservation = formattedObservation.replace(/\b_\w+/g, `<span id="obs-normal" style="background-color: #ffff00; color: black;">$&</span>`);
      temp_observation += `<p id="obs-normal">` + formattedObservation+ '</p>';
    });

    if (!['ab manual', 'ab other', 'other'].includes(organ)) {
      if (temp_observation) {
        organ = organ.replace('ab ', '');
        if(['cbd', 'rif'].includes(organ)){
          observation += `\n<p id="obs-title"><strong>` + organ.toUpperCase() + ':</strong></p>';
        }
        else{
          observation += `\n<p id="obs-tittle"><strong>` + organ.charAt(0).toUpperCase() + organ.slice(1) + ':</strong></p>';
        }
      }
    }
    else if(['ab manual', 'ab other', 'other'].includes(organ) && temp_observation.length){
      observation += '\n'
    }
  
    observation += temp_observation;
    impression += temp_impression;
  });

  return observation + impression;
};



export function getAdditionalText(question, selectedOptions) {
  let additionalText = '';
  const appendedTextSet = new Set(); 

  for (const input of question) {
    if (input.followup === 'true') {
      let selectedOption = selectedOptions[input.key];

      if (selectedOption) {
        const selectedOptionsArray = typeof selectedOption === 'string' && selectedOption.includes(',')
          ? selectedOption.split(',').map(opt => opt.trim())
          : [selectedOption];

        for (const option of selectedOptionsArray) {
          let followupInput;

          if (input.type === 'input box') {
            followupInput = input.to_followup[input.key];
          } else {
            followupInput = input.to_followup[option];
          }

          if (followupInput && !appendedTextSet.has(followupInput.additional_text)) { 

            if (followupInput.additional_text) {
              additionalText += followupInput.additional_text + ' ';
              appendedTextSet.add(followupInput.additional_text); 
            } else if (Array.isArray(followupInput)) {
              additionalText += getAdditionalText(followupInput, selectedOptions);
            } else if (typeof followupInput === 'object') {
              for (const key in followupInput) {
                if (followupInput[key].additional_text && !appendedTextSet.has(followupInput[key].additional_text)) {
                  additionalText += followupInput[key].additional_text + ' ';
                  appendedTextSet.add(followupInput[key].additional_text);
                }
              }
            }
          }
        }
      }
    }
  }

  Object.entries(selectedOptions).forEach(([key, value]) => {
    const placeholder = `_${key}`;
    additionalText = additionalText.replace(new RegExp(placeholder, 'g'), value);
  });

  return additionalText.trim();
}



export const renderInput = (input, options, pathology, handleInputChange, measurementSize, updateMeasurementSize, pathologyFormData, theme, followup = true) => {
  if (!input) return null;

  if (Array.isArray(input)) {
    return input.map((item, index) =>
      renderInputItem(item, options, pathology, handleInputChange, measurementSize, updateMeasurementSize, pathologyFormData, theme, followup, index)
    );
  }

  return renderInputItem(input, options, pathology, handleInputChange, measurementSize, updateMeasurementSize, pathologyFormData, theme, followup);
};

const renderInputItem = (input, options, pathology, handleInputChange, measurementSize, updateMeasurementSize, pathologyFormData, theme, followup, index = null) => {


  const dropDownOptions = options[input.key] ? options[input.key].map((optionValue) => ({ value: optionValue, label: optionValue })) : [];
  const key = index !== null ? `${input.key}_${index}` : input.key;
  
  switch (input.type) {
    case 'heading':
      return (
        <h2 key={key} className={`${theme.secondaryColor}`}>
          Do answer the following question on <b>{input.value}</b>
          {/* {followup && input.followup === 'true' && pathologyFormData?.[pathology]?.[input.key] && input.to_followup[pathologyFormData[pathology][input.key]] !== 'NA'
            && renderInput(input.to_followup[input.key], options, pathology, handleInputChange, measurementSize, updateMeasurementSize, pathologyFormData, theme)} */}
        </h2>
      );
    case 'size':
      return (
        <div key={key}>
          <label className={`${theme.secondaryColor}`}>{input.value}</label>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '200px' }}>
            <input
              type="number"
              name={`${input.key}_CC`}
              className={`border-none bg-transparent px-1 ${theme.secondaryColor}`}
              placeholder="---"
              required
              style={{ width: '50px' }}
              onChange={(e) => updateMeasurementSize(e, input.key, 'CC', pathology)}
            />
            <input
              type="number"
              name={`${input.key}_AP`}
              className={`border-none bg-transparent px-1 ${theme.secondaryColor}`}
              placeholder="---"
              required
              style={{ width: '50px' }}
              onChange={(e) => updateMeasurementSize(e, input.key, 'AP', pathology)}
            />
            <input
              type="number"
              name={`${input.key}_TR`}
              className={`border-none bg-transparent px-1 ${theme.secondaryColor}`}
              placeholder="---"
              required
              style={{ width: '50px' }}
              onChange={(e) => updateMeasurementSize(e, input.key, 'TR', pathology)}
            />
            <input
              type="hidden"
              name={input.key}
              value={`${measurementSize.CC} x ${measurementSize.AP} x ${measurementSize.TR}`}
            />
          </div>
        </div>
      );
    case 'multiple choice':
      return (
        <div key={key}>
          <label>{input.value}</label>
          <Select
            isMulti
            name={input.key}
            options={dropDownOptions}
            classNamePrefix="select"
            maxMenuHeight={200}
            placeholder="---"
            menuPortalTarget={document.body}
            onChange={(event) => { handleInputChange(input.key, event.map(option => option.value).join(', '), pathology) }}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                backgroundColor: theme.primaryColor,
                borderColor: theme.secondaryColor,
                color: theme.secondaryColor
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
              ...theme.colors,
                primary: '#F3F4F6',
                primary25: '#212529',
                neutral0: '#212529',
                neutral10: '#212529',
                neutral80:'#F3F4F6',
              },
            })}
           
          />
        </div>
      );
    case 'single choice':
      return (
        <div style={{ backgroundColor: theme.primaryColor, padding: '1px' }} key={key}>
          <label >{input.value}</label>
          <Select
            name={input.key}
            options={dropDownOptions}
            placeholder={pathologyFormData?.[pathology]?.[input.key] ? pathologyFormData[pathology][input.key] : '---'}
            classNamePrefix="select"
            maxMenuHeight={200}
            menuPortalTarget={document.body}
            onChange={(event) => { handleInputChange(input.key, event.value, pathology) }}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                backgroundColor: theme.primaryColor,
                borderColor: theme.secondaryColor,
                color: theme.secondaryColor
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
              ...theme.colors,
                primary: '#F3F4F6',
                primary25: '#212529',
                neutral0: '#212529',
                neutral5: '#212529',
                neutral80:'#F3F4F6',
              },
            })}
            
/>
          {followup && input.followup === 'true' && pathologyFormData?.[pathology]?.[input.key] && input.to_followup[pathologyFormData[pathology][input.key]] !== 'NA' && input.to_followup[pathologyFormData[pathology][input.key]]
            && renderInput(input.to_followup[pathologyFormData[pathology][input.key]], options, pathology, handleInputChange, measurementSize, updateMeasurementSize, pathologyFormData, theme)}
        </div>
      );
    case 'input box':
      return (
        <div key={key}>
          <label className={`${theme.secondaryColor}`}>{input.value}</label>
          <input
            type="text"
            onChange={(e) => handleInputChange(input.key, e.target.value, pathology)}
            className={`border-none bg-transparent px-1 ${theme.secondaryColor}`}
            style={{ backgroundColor: 'transparent', borderBottom: '1px solid', width: '15%' }}
          />
          <p> <br></br> </p>
        </div>
      );
    case 'link':
      return (
        <p key={key} className={`${theme.secondaryColor}`}>
          <br></br>
          {input.value}
          <a className={`underline text-blue-600 hover:text-blue-800 visited:text-purple-600 ${theme.secondaryColor}`}
            href={input.url} target="_blank" rel="noopener noreferrer">
            link
          </a>
        </p>
      );
    default:
      return null;
  }
};


export function copyHTMLToClipboard(htmlContent) {
  const tempElem = document.createElement('div');
  tempElem.innerHTML = htmlContent;
  tempElem.style.position = 'absolute';
  tempElem.style.left = '-9999px';
  document.body.appendChild(tempElem);
  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(tempElem);
  selection.removeAllRanges();
  selection.addRange(range);
  try {
    document.execCommand('copy');
  } catch (err) {
    console.log('Oops, unable to copy');
  }
  document.body.removeChild(tempElem);
}


export function getOptionFromReportContents(reportContents, pathology, label) {
  const report = reportContents?.find(item => item.pathology === pathology);
  if (report) {
    return report.variables[label.replace('_', '')]
  }
}

export function updateObservationVariables(reportContents, pathologyFormData) {

  return reportContents.map(content => {
    if (content.pathology !== 'normal' && content.pathology !== 'manual') {
      let updatedObservation = content.observation;

      for (const [key, value] of Object.entries(pathologyFormData[content.pathology] || {})) {
        const variableName = `_${key}`;
        const variableRegex = new RegExp(variableName, 'g');

        if (typeof value === 'string') {
          updatedObservation = updatedObservation.replace(variableRegex, value);
        }
      }

      return {
        ...content,
        observation: updatedObservation,
      };
    }
    return content;
  });
}


export function getAdditionalQuestions(reportContents, pathologyFormData, pathology) {
  const additionalQuestions = [];
  function processQuestion(questionObj, pathology, variable) {
    if (questionObj === '__mandate__'){
      return;
    }
    const content = reportContents.find((c) => c.pathology === pathology);
    if (content && !content.observation.includes(`_${questionObj.key}`)) {
      if ((!pathologyFormData[pathology] || !pathologyFormData[pathology][questionObj.key]) && !questionObj.hasOwnProperty('additional_text')) {
        const existingPathologyIndex = additionalQuestions.findIndex(
          (q) => q.pathology === pathology
        );

        if (existingPathologyIndex !== -1) {
          additionalQuestions[existingPathologyIndex].question.push(questionObj);
        } 
        else {
          additionalQuestions.push({
            question: [{ type: 'heading', key: '', value: content.pathology.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }, questionObj],
            pathology,
            variable,
          });
        }
      }
    }

    if (questionObj.followup === 'true' && pathologyFormData[pathology] && pathologyFormData[pathology][questionObj.key]) {
      const selectedOption = pathologyFormData[pathology][questionObj.key];
      const followupQuestion = questionObj.to_followup[selectedOption];
      if (followupQuestion && followupQuestion !== 'NA') {
        processQuestion(followupQuestion, pathology, variable);
      }
    }
  }

  const content = reportContents.find((c) => c.pathology === pathology);
  if (content && content.question) {
    content.question.forEach((questionObj) => {
        processQuestion(questionObj, pathology, content.variables);
    });
  }

  const filteredAdditionalQuestions = additionalQuestions.filter((item) => {
    return item.question.length > 1 && !item.question.includes('additional text');
  });

  return filteredAdditionalQuestions;
}


export function checkForEligibleAdditionalQuestion(reportContents, pathologyFormData) {
  const eligiblePathologies = [];

  reportContents.forEach((content) => {
    if (content.pathology !== 'normal' && content.pathology !== 'manual') {
      const underscoreVariables = content.observation.match(/_\w+/g) || [];

      if (underscoreVariables.length) {
        const allVariablesPresent = underscoreVariables.every((variable) => {
          const extractedVariable = variable.slice(1);
          return pathologyFormData[content.pathology] && pathologyFormData[content.pathology][extractedVariable];
        });


        if (allVariablesPresent) {
          eligiblePathologies.push(content.pathology);
        }
      }
    }
  });

  return getAdditionalQuestions?.(reportContents, pathologyFormData, eligiblePathologies?.[0])[0]
}